import React, { useEffect, useState } from 'react';
import {
    Alert,
    AppState,
    FlatList,
    Image,
    Pressable,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Platform, I18nManager,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { navigationPropType } from '../propTypes';
import GLOBAL from '../config/globals';

// components
// not supported by web -> should work now
import { Banner, ScrollIndicatorWrapper, Spinner } from '../components/shared';
import QuestionnaireModal from '../components/questionnaireModal/questionnaireModal';
// not used here
import CategoriesList from '../components/survey/categoriesList';

// services
import translate, { getLanguageTag } from '../services/localization';
import { appConfig, theme } from '../config';
import exportService from '../services/questionnaireAnalyzer';

// redux actions
import {
  fetchQuestionnaire,
  setContinueWithCategoryIndex,
  switchContent,
} from '../store/questionnaire.slice';
import { sendQuestionnaireResponse } from '../store/sharedActions';

import { Routes } from '../navigation/constants';
import NetInfo, { useNetInfo } from '@react-native-community/netinfo';
import endpoints from '../services/rest/endpoints';
// not used here
// import DashboardButton from '../components/ui/DashboardButton';
import RNModal from 'react-native-modal';
import { Button, Dialog, IconButton } from 'react-native-paper';
import PersonButton from '../components/ui/PersonButton';
import { formatDateString } from '../services/utils';

import {
  fetchRegistryErstmeldebogenEltern,
  fetchRegistryErstmeldebogenKind,
  fetchRegistryVerlaufsbogenKind,
  fetchRegistryLaborwerte,
  fetchRegistryLabordocs,
} from '../store/registry.slice';
import AppLock from '../components/security/AppLock';
import { setIsBiometryAuthenticated } from '../store/globals.slice';
import moment from 'moment';
import { setGlobalCurrentKind } from '../store/user.slice';
import alert from '../components/shared/Alert';
import RenderHtml from "react-native-render-html";

/***********************************************************************************************
 * renders the survey-screen with the list of all categories, the modal to answer the questionnaire
 * and a button to send the response to the server
 *
 * @param  {object}    props
 * @param  {object}    props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function SurveyScreen({ navigation }) {
  const dispatch = useDispatch();

  const {itemMap, categories, FHIRmetadata, retrieveDataCycle, continueWithCategoryIndex} = useSelector(
        (state) => state.Questionnaire,
    );
  const {globalCurrentKind} = useSelector(
    (state) => state.User,
  );
const [currentKind, setCurrentKind] = useState({});
    const [labMissing, setLabMissing] = useState(false);
    const [labMissingDaysLeft, setLabMissingDaysLeft] = useState(null);

    // for alerts in web
    const [showDialog, setShowDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogButtons, setDialogButtons] = useState([]);

    useEffect(() => {
        setCurrentKind(globalCurrentKind);
    }, [globalCurrentKind]);

    useEffect(() => {
        let lastEntryDate = currentKind.kind_datum_eintragung;
        let lastLabId = currentKind.kind_lab_id;
        registryDataVerlaufsbogenKind?.forEach((verlaufsbogen, i) => {
            if (verlaufsbogen.ver_kind_id === currentKind.kind_kind_id && !!verlaufsbogen.ver_lab_id) {
                if (lastEntryDate < verlaufsbogen.ver_datum_eintragung) {
                    lastEntryDate = verlaufsbogen.ver_datum_eintragung;
                    lastLabId = verlaufsbogen.ver_lab_id;
                }
            }
        });
        GLOBAL.lastLabId = lastLabId;

        // let foundLaborwert = false;
        // registryLaborwerte?.forEach((laborwert) => {
        //     if (laborwert.labor_lab_id === lastLabId) {
        //         foundLaborwert = laborwert;
        //     }
        // });
        // if (!foundLaborwert) {
        //     registryLabordocs?.forEach((labordoc) => {
        //         if (labordoc.labordocs_lab_id === lastLabId) {
        //             foundLaborwert = labordoc;
        //         }
        //     });
        // }


        let daysLeft = 30 - moment()
            .diff(moment(lastEntryDate?.substring(0, 10)), 'day')
        setLabMissingDaysLeft(daysLeft > 0 ? daysLeft : 0);
        // setLabMissing(!foundLaborwert);
    }, [currentKind]);

    const {loading, error} = useSelector((state) => state.Globals);
    const {current_questionnaire_id, subjectId, start_date} = useSelector(
        (state) => state.User,
    );

    const [currentItem, setCurrentItem] = useState({})

  NetInfo.configure({
    reachabilityUrl: endpoints.ping,
    reachabilityTest: async (response) => response.status === 204,
    reachabilityLongTimeout: 60 * 1000, // 60s
    reachabilityShortTimeout: 5 * 1000, // 5s
    reachabilityRequestTimeout: 3 * 1000, // 15s
    reachabilityShouldRun: () => true,
    shouldFetchWiFiSSID: true, // met iOS requirements to get SSID. Will leak memory if set to true without meeting requirements.
    useNativeReachability: true,
  });
  const netInfo = useNetInfo();

  // when the component loads and no categories are present - i.e. no questionnaire has previously been fetched
  // get questionnaire from backend if no error ocurred before
  useEffect(() => {
    console.log('CALCULATION 2');
    if (!categories && !error && !loading) {
      if (new Date() > new Date(start_date)) {
        dispatch(
          fetchQuestionnaire({
            questionnaireID: current_questionnaire_id,
            subjectId,
          }),
        );
      } else {
        navigation.navigate(Routes.CHECK_IN);
      }
    }
  }, [
    categories,
    dispatch,
    error,
    loading,
    current_questionnaire_id,
    subjectId,
    navigation,
    start_date,
  ]);

  const {
    registryDataEltern,
    registryDataErstmeldebogenKind,
    registryDataVerlaufsbogenKind,
    registryLaborwerte,
    registryLabordocs
  } = useSelector((state) => state.RegistryData);

  const [continueCategoryIndex, setContinueCategoryIndex] = useState(-1);

  useEffect(() => {
    setContinueCategoryIndex(continueWithCategoryIndex);
  }, [continueWithCategoryIndex]);

  useEffect(() => {
    dispatch(
      fetchRegistryErstmeldebogenEltern({
        subjectId,
      }),
    );
  }, [retrieveDataCycle]);
  useEffect(() => {
    dispatch(
      fetchRegistryErstmeldebogenKind({
        subjectId,
      }),
    );
  }, [retrieveDataCycle]);
  useEffect(() => {
    dispatch(
      fetchRegistryVerlaufsbogenKind({
        subjectId,
      }),
    );
  }, [retrieveDataCycle]);
  useEffect(() => {
    dispatch(
      fetchRegistryLaborwerte({
        subjectId,
      }));
    }, [retrieveDataCycle]);

    useEffect(() => {
        dispatch(fetchRegistryLabordocs({
            subjectId,
        }));
  }, [retrieveDataCycle]);

  useEffect(() => {
    if (continueCategoryIndex !== -1) {
      dispatch(switchContent({categoryIndex: continueCategoryIndex}));
      dispatch(setContinueWithCategoryIndex(-1));
    }
  }, [retrieveDataCycle]);

  let usedElternIds = [];
  let usedKindIds = [];
  const [shownEltern, setShownEltern] = useState([]);
  const [shownKind, setShownKind] = useState([]);

  const [showDashEltern, setShowDashEltern] = useState(false);
  const [showDashKind, setShowDashKind] = useState(false);

  useEffect(() => {
    setShownEltern([]);
    usedElternIds = {};
    registryDataEltern?.map((eltern) => {
      if (!Object.keys(usedElternIds).includes(eltern.elternteil_id)) {
        usedElternIds[eltern.elternteil_id] = eltern;
      } else {
        if (
          usedElternIds[eltern.elternteil_id].elternteil_eintragung_datum <=
          eltern.elternteil_eintragung_datum
        ) {
          usedElternIds[eltern.elternteil_id] = eltern;
        }
      }
    });
    let newElements = [];
    let vals = Object.values(usedElternIds);
    for (let id = 0; id < vals.length; id++) {
      newElements.push({
        id: id + 1,
        eltern: vals[id],
      });
    }
    newElements.push({
      id: -1,
    });
    if (vals.length % 2 === 0) {
      newElements.push({
        id: -100,
      });
    }
    console.log('newElements');
    console.log(newElements);
    setShownEltern(newElements);
  }, [registryDataEltern]);

  useEffect(() => {
    setShownKind([]);
    usedKindIds = {};
    registryDataErstmeldebogenKind?.map((kind) => {
      if (!Object.keys(usedKindIds).includes(kind.kind_kind_id)) {
        usedKindIds[kind.kind_kind_id] = kind;
      } else {
        if (
          usedKindIds[kind.kind_kind_id].eintragung_datum_kind <=
          kind.eintragung_datum_kind
        ) {
          usedKindIds[kind.kind_kind_id] = kind;
        }
      }
    });
    let newElements = [];
    let vals = Object.values(usedKindIds);
    for (let id = 0; id < vals.length; id++) {
      newElements.push({
        id: id + 1,
        kind: vals[id],
      });
    }
    newElements.push({
      id: -1,
    });
    if (vals.length % 2 === 0) {
      newElements.push({
        id: -100,
      });
    }
    console.log(newElements);
    setShownKind(newElements);
  }, [registryDataErstmeldebogenKind]);

  // check if all categories of the questionnaire have been answered as required
  const done = categories?.every((category) => itemMap[category.linkId].done);

  /**
   * handle submission of questionnaire
   */
  const handleSubmit = () => {
    alert(
      translate('generic').info,
      translate('survey').sendFinishedMessage,
      [
        {
          text: translate('survey').sendFinished,
          onPress: () => {
            if (
              NetInfo.refresh().then((state) => {
                if (state.isInternetReachable) {
                  dispatch(
                    sendQuestionnaireResponse({
                      body: exportService.createResponseJSON(
                        itemMap,
                        categories,
                        FHIRmetadata,
                      ),
                    }),
                  );
                  navigation.navigate(Routes.CHECK_IN);
                } else {
                  alert(
                    translate('generic').errorNoInternetTitle,
                    translate('generic').errorNoInternet,
                    [
                      {
                        text: translate('generic').ok,
                      },
                    ],
                    { cancelable: false },
                  );
                }
              })
            );
          },
        },
        {
          text: translate('generic').abort,
          style: 'cancel',
        },
      ],
      { cancelable: false },
    );
  };

  let totalQuestions = 0;
  let mandatoryQuestions = 0;
  let completedQuestions = 0;
  for (const i in itemMap) {
    totalQuestions++;
    mandatoryQuestions += itemMap[i].required ? 1 : 0;
    completedQuestions += itemMap[i].done ? 1 : 0;
  }
  console.log('CALCULATION 1');

  const images = GLOBAL.images;

  const { isBiometryAuthenticated } = useSelector((state) => state.Globals);
  const { useAppLock } = useSelector((state) => state.User);

  useEffect(() => {
    // only make use of app lock on native application
    if (Platform.OS === 'native') {
      setAppLock(useAppLock);
    }
  }, [useAppLock]);
  useEffect(() => {
    setIsAuthenticated(isBiometryAuthenticated);
  }, [isBiometryAuthenticated]);
  const [appLock, setAppLock] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [appState, setAppState] = useState('unknown');
  const handleAppStateChange = (newAppState) => {
    console.log('newAppState', newAppState);
    setAppState(newAppState);
    if (Platform.OS === 'native' && (newAppState === 'background' || newAppState === 'unknown')) {
      dispatch(setIsBiometryAuthenticated(false));
    }
  };

  useEffect(() => {
      AppState.addEventListener('change', handleAppStateChange);
      return () => {
        AppState.removeEventListener('change', handleAppStateChange);
    }
  }, []);

  if (Platform.OS === 'native' && !isAuthenticated && appLock && appState === 'background') {
    return <></>;
  } else if (
    Platform.OS === 'native' &&
    !isAuthenticated &&
    appLock &&
    (appState === 'active' || appState === 'inactive')
  ) {
    return <AppLock />;
  }


  const numColumns = Platform.OS === 'web' ? 3 : 2;

  //  for web, ScrollView won't be displayed properly -> use regular View
  //  for native: use ScrollView
  const ContentView = ({ children, ...props }) => {
  if (Platform.OS === 'web') {
    return <View {...props}>{children}</View>;
  } else {
    return <ScrollView {...props}>{children}</ScrollView>;
  }
};

  // renders scrollview for web, and not for native
  const ScrollViewWeb = ({ children, ...props }) => {
    if (Platform.OS === 'web') {
      return <ScrollView {...props}>{children}</ScrollView>;
    } else {
      return {children};
    }
  };


  return loading ? (
    <Spinner />
  ) : (
    <View style={[localStyle.flexi, localStyle.wrapper]} testID="SurveyScreen">
        <Image
            source={require('../assets/images/illuStartseite.png')}
            resizeMode="contain"
            style={localStyle.headerImage}
        ></Image>
      {/* render the top banner */}
      <Banner nav={navigation} title={translate('survey').title} />

      {/* the questionnaire modal */}
      <QuestionnaireModal nav={navigation} />

      <ContentView style={localStyle.content}>
        <Text style={localStyle.familyTitle}>Eltern</Text>

        <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
          <FlatList
            data={shownEltern}
            numColumns={numColumns} // Set the number of columns to 2
            keyExtractor={(item) => item.id.toString()}
            renderItem={({ item }) => {
              if (item.id === -100) {
                return <View style={{ width: '50%' }}></View>;
              }
              if (item.id === -1) {
                if (shownEltern.length <= 2) {
                  return (
                    <>
                      <View>
                        <PersonButton
                          name={'Elternteil\nhinzufügen'}
                          image={
                            <Image
                              source={require('../assets/images/add.png')}
                              resizeMode="contain"
                              style={[localStyle.dashModalIcon]}
                            ></Image>
                          }
                          onPress={() => {
                            GLOBAL.currentEltern = null;
                            GLOBAL.currentInstrument = 'erstmeldebogen_eltern';
                            dispatch(switchContent({ categoryIndex: 0 }));
                          }}
                        />
                      </View>
                    </>
                  );
                } else {
                  return <View style={{ width: '50%' }}></View>;
                }
              }
              let eltern = item.eltern;
              let elternTyp;
              let imgFile = images[eltern.elternteil_anrede].uri;
              switch (eltern.elternteil_anrede) {
                case 'M':
                  elternTyp = 'Mutter';
                  break;
                case 'V':
                  elternTyp = 'Vater';
                  break;
                case 'E':
                  elternTyp = 'Vormund';
                  break;
              }

              return (
                <>
                  <View>
                    <PersonButton
                      name={
                        eltern.elternteil_vorname +
                        ' ' +
                        eltern.elternteil_nachname
                      }
                      image={
                        <Image
                          source={imgFile}
                          resizeMode="contain"
                          style={[localStyle.dashModalIcon]}
                        ></Image>
                      }
                      onPress={() => {
                        GLOBAL.currentEltern = eltern;
                        GLOBAL.currentInstrument = 'erstmeldebogen_eltern';
                        setShowDashEltern(true);
                        // dispatch(switchContent({categoryIndex: 0}));
                      }}
                    />
                  </View>
                  {/*<DashboardButton text={eltern.elternteil_vorname + ' ' + eltern.elternteil_nachname}*/}
                  {/*                 onPress={*/}
                  {/*                     () => {*/}
                  {/*                         GLOBAL.currentEltern = eltern;*/}
                  {/*                         GLOBAL.currentInstrument = 'erstmeldebogen_eltern';*/}
                  {/*                         setShowDashEltern(true);*/}
                  {/*                         // dispatch(switchContent({categoryIndex: 0}));*/}
                  {/*                     }*/}
                  {/*                 }*/}
                  {/*                 img={imgFile}/>*/}
                </>
              );
            }}
          />
        </View>

        <Text style={localStyle.familyTitle}>Kinder</Text>

        <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
          <FlatList
            data={shownKind}
            numColumns={numColumns} // Set the number of columns to 2
            keyExtractor={(item) => item.id.toString()}
            renderItem={({ item }) => {
              if (item.id === -100) {
                return <View style={{ width: '50%' }}></View>;
              }
              if (item.id == -1) {
                return (
                  <>
                    <View>
                      <PersonButton
                        name={'Kind\nhinzufügen'}
                        image={
                          <Image
                            source={require('../assets/images/add.png')}
                            resizeMode="contain"
                            style={[localStyle.dashModalIcon]}
                          ></Image>
                        }
                        onPress={() => {
                          setCurrentKind({});
                          dispatch(setGlobalCurrentKind({}));
                          GLOBAL.currentKind = {};
                          GLOBAL.currentInstrument = 'erstmeldebogen_kind';
                          dispatch(switchContent({ categoryIndex: 1 }));
                        }}
                      />
                    </View>
                  </>
                );
              }
              let kind = item.kind;
              if (!kind.kind_geschlecht) {
                return;
              }
              let imgFile = images[kind.kind_geschlecht].uri;
              return (
                <>
                  <View>
                    <PersonButton
                      name={kind.kind_vorname + ' ' + kind.kind_nachname}
                      image={
                        <Image
                          source={imgFile}
                          resizeMode="contain"
                          style={[localStyle.dashModalIcon]}
                        ></Image>
                      }
                      onPress={() => {
                        GLOBAL.currentKind = kind;
                        dispatch(setGlobalCurrentKind(kind));
                        setShowDashKind(true);
                        // dispatch(switchContent({categoryIndex: 1}));
                      }}
                    />
                  </View>
                  {/*<DashboardButton text={kind.kind_vorname + ' ' + kind.kind_nachname}*/}
                  {/*                 onPress={*/}
                  {/*                     () => {*/}
                  {/*                         GLOBAL.currentKind = kind;*/}
                  {/*                         GLOBAL.currentInstrument = 'erstmeldebogen_kind';*/}
                  {/*                         setShowDashKind(true);*/}
                  {/*                         // dispatch(switchContent({categoryIndex: 1}));*/}
                  {/*                     }*/}
                  {/*                 }*/}
                  {/*                 img={imgFile}/>*/}
                </>
              );
            }}
          />
        </View>

        {/*<View style={[localStyle.flexi, localStyle.wrapper]}>*/}
        {/*    /!* creates the list items for the categories *!/*/}
        {/*    <CategoriesList*/}
        {/*        showQuestionnaireModal={(categoryIndex, pageIndex) => {*/}
        {/*            dispatch(switchContent({categoryIndex, pageIndex}));*/}
        {/*        }}*/}
        {/*        categories={categories}*/}
        {/*        itemMap={itemMap}*/}
        {/*        FHIRmetadata={FHIRmetadata}*/}
        {/*    />*/}
        {/*</View>*/}
      </ContentView>
      {/* renders a send-button at the bottom if the questionnaire is completed */}
      <View style={localStyle.bottom}>
        {/*{done && (*/}
        {/*    <TouchableOpacity*/}
        {/*        accessibilityLabel={translate('survey').send}*/}
        {/*        accessibilityRole={translate('accessibility').types.button}*/}
        {/*        accessibilityHint={*/}
        {/*            translate('accessibility').questionnaire.sendHint*/}
        {/*        }*/}
        {/*        onPress={handleSubmit}*/}
        {/*        style={[localStyle.button, localStyle.buttonComplete]}*/}
        {/*    >*/}
        {/*        <Text style={localStyle.buttonLabel}>*/}
        {/*            {translate('survey').send}*/}
        {/*        </Text>*/}
        {/*    </TouchableOpacity>*/}
        {/*)}*/}
        {/* renders a button to retry fetching of questionnaire */}
        {error?.failedAction === 'questionnaire/FETCH' && (
          <TouchableOpacity
            accessibilityLabel={translate('login').landing.retry}
            accessibilityRole={translate('accessibility').types.button}
            accessibilityHint={translate('accessibility').refreshHint}
            onPress={() =>
              dispatch(
                fetchQuestionnaire({
                  questionnaireID: current_questionnaire_id,
                  subjectId,
                }),
              )
            }
            style={[localStyle.button, localStyle.buttonAlert]}
          >
            <Text style={localStyle.buttonLabel}>
              {translate('login').landing.retry}
            </Text>
          </TouchableOpacity>
        )}
      </View>

      {showDashEltern && (
        <RNModal
          scrollOffsetMax={100}
          avoidKeyboard={true}
          propagateSwipe
          coverScreen={() => {
            Platform.OS === 'native' ? false : true;
          }}
          transparent={true}
          onBackdropPress={() => setShowDashEltern(false)}
          style={[localStyle.dashModal]}
          isVisible={showDashEltern}
        >
          <ScrollViewWeb
            contentContainerStyle={{ flexGrow: 1 }}
            showsVerticalScrollIndicator={false}
          >
            <View
              style={{ backgroundColor: theme.colors.white, borderRadius: 10 }}
            >
              <View style={{ flexDirection: 'row', alignSelf: 'flex-end' }}>
                <IconButton
                  icon="close"
                  iconColor={'black'}
                  size={30}
                  onPress={() => {
                    setShowDashEltern(false);
                  }}
                  containerColor={theme.colors.white}
                  mode={'contained'}
                  style={
                    Platform.OS === 'native' && {
                      flexDirection: 'row',
                      alignSelf: 'flex-end',
                      height: 30,
                      width: 30,
                      margin: 10,
                    }
                  }
                />
              </View>

              <TouchableOpacity
                onPress={() => {
                  Platform.OS === 'web' && setShowDashEltern(false);
                  dispatch(switchContent({ categoryIndex: 0 }));
                }}
                style={{
                  backgroundColor: '#EFEFEF',
                  padding: 20,
                  marginBottom: 10,
                }}
              >
                <View style={{ flexDirection: 'row' }}>
                  <Image
                    source={images[GLOBAL.currentEltern.elternteil_anrede].uri}
                    resizeMode="contain"
                    style={[localStyle.dashModalIcon, { marginRight: 15 }]}
                  ></Image>

                  <View style={{ flexDirection: 'column', width: '60%' }}>
                    <Text
                      style={[
                        localStyle.familyTitle,
                        {
                          marginLeft: 0,
                          marginTop: 0,
                          marginBottom: 8,
                        },
                      ]}
                    >
                      {GLOBAL.currentEltern.elternteil_vorname +
                        ' ' +
                        GLOBAL.currentEltern.elternteil_nachname}
                    </Text>

                    <Text>{GLOBAL.currentEltern.elternteil_strasse}</Text>
                    <Text>
                      {GLOBAL.currentEltern.elternteil_plz}{' '}
                      {GLOBAL.currentEltern.elternteil_wohnort}
                    </Text>
                    <Text>{GLOBAL.currentEltern.elternteil_land}</Text>
                  </View>

                  <View
                    style={{
                      height: '100%',
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                      alignSelf: 'center',
                    }}
                  >
                    <Image
                      source={require('../assets/images/edit.png')}
                      resizeMode="contain"
                      style={{
                        marginLeft: 20,
                        marginTop: -40,
                        height: 40,
                        width: 40,
                      }}
                    ></Image>
                  </View>
                </View>

                <View style={{ marginTop: 8, marginLeft: 75 }}>
                  <Text>{GLOBAL.currentEltern.elternteil_email}</Text>
                  <Text>{GLOBAL.currentEltern.elternteil_telefon}</Text>
                </View>
              </TouchableOpacity>
            </View>
          </ScrollViewWeb>
        </RNModal>
      )}

      {showDashKind && currentKind?.kind_kind_id && (
        <RNModal
          scrollOffsetMax={100}
          avoidKeyboard={() => {
            Platform.OS === 'native' ? true : false;
          }}
          propagateSwipe
          coverScreen={() => {
            Platform.OS === 'native' ? false : true;
          }}
          transparent={true}
          onBackdropPress={() => setShowDashKind(false)}
          style={[localStyle.dashModal, { marginTop: 20 }]}
          isVisible={showDashKind}
        >
          <ScrollViewWeb
            contentContainerStyle={{ flexGrow: 1 }}
            showsVerticalScrollIndicator={false}
          >
            <View
              style={{
                backgroundColor: theme.colors.white,
                borderRadius: 10,
                paddingTop: 5,
                paddingBottom: 15,
              }}
            >
              <IconButton
                icon="close"
                iconColor={'black'}
                size={30}
                onPress={() => {
                  setShowDashKind(false);
                }}
                containerColor={theme.colors.white}
                mode={'contained'}
                style={
                  Platform.OS === 'native' && {
                    flexDirection: 'row',
                    alignSelf: 'flex-end',
                    height: 30,
                    width: 30,
                    margin: 10,
                  }
                }
              />

              <View
                style={{
                  ...(Platform.OS === 'native' && { padding: 20 }),
                  ...(Platform.OS === 'native' && {
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingBottom: 20,
                  }),
                }}
              >
                <View style={{ flexDirection: 'row' }}>
                  {images?.[currentKind?.kind_geschlecht]?.uri && (
                    <Image
                      source={images[currentKind.kind_geschlecht].uri}
                      resizeMode="contain"
                      style={[localStyle.dashModalIcon, { marginRight: 15 }]}
                    ></Image>
                  )}
                  <View
                    style={{
                      flexDirection: 'column',
                      width: '60%',
                      paddingTop: 6,
                    }}
                  >
                    <Text
                      style={[
                        localStyle.familyTitle,
                        {
                          marginLeft: 0,
                          marginTop: 0,
                          marginBottom: 8,
                        },
                      ]}
                    >
                      {currentKind.kind_vorname +
                        ' ' +
                        currentKind.kind_nachname}
                    </Text>
                    <Text>
                      geb. am{' '}
                      {formatDateString(new Date(currentKind.kind_geb), {
                        locale: getLanguageTag(),
                      })}
                    </Text>
                  </View>
                </View>
              </View>

              {currentKind.kind_gesund !== '1' && (
                <>
                  <View>
                    <Pressable
                      style={[localStyle.dlgButton]}
                      onPress={() => {
                        const age = moment().diff(
                          moment(currentKind.kind_geb, 'YYYY-MM-DD'),
                          'years',
                        );
                        if (age >= 18) {
                          if (Platform.OS === 'native') {
                            alert(
                              'Volljährigkeit erreicht',
                              currentKind.kind_vorname +
                                ' ' +
                                currentKind.kind_nachname +
                                ' hat die Volljährigkeit erreicht. Weitere Meldungen für Ihr Kind müssen nun im Erwachsenenregister vorgenommen werden. ',
                              [
                                {
                                  text: translate('generic').ok,
                                },
                              ],
                              { cancelable: false },
                            );
                            return;
                          } else {
                            setDialogTitle('Volljährigkeit erreicht');
                            setDialogMessage(currentKind.kind_vorname +
                              ' ' +
                              currentKind.kind_nachname +
                              ' hat die Volljährigkeit erreicht. Weitere Meldungen für Ihr Kind müssen nun im Erwachsenenregister vorgenommen werden.');
                            setDialogButtons([
                              {
                                text: translate('generic').ok,
                              },
                            ]);
                            setShowDialog(true);
                            return;
                          }
                        }

                        // setShowDashKind(false);
                        GLOBAL.currentInstrument = 'verlaufsbogen';
                        GLOBAL.currentKind = currentKind;
                        dispatch(switchContent({ categoryIndex: 2 }));

                        // otherwise the RNModal is still on top of screen
                        Platform.OS === 'web' ? setShowDashKind(false) : null;
                      }}
                    >
                      <View style={{ paddingRight: 40 }}>
                        <View style={{ width: '100%', height: 200 }}>
                          <Text
                            style={[
                              {
                                marginRight: 10,
                                fontSize: 15,
                                marginTop: 22,
                                textAlign: 'left',
                                ...(Platform.OS === 'web' && {
                                  textColor: theme.colors.primary,
                                }),
                              },
                            ]}
                          >
                            Verlaufsbogen eintragen
                          </Text>
                        </View>
                      </View>

                      <Image
                        source={require('../assets/images/add.png')}
                        style={{
                          height: 40,
                          width: 40,
                          marginRight: 0,
                          marginTop: -15,
                          paddingLeft: 15,
                          marginLeft: 'auto',
                          alignContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'row',
                        }}
                      />
                    </Pressable>
                  </View>

                  {labMissingDaysLeft > 0 && (
                    <Pressable
                      style={[localStyle.dlgButton]}
                      onPress={() => {
                        GLOBAL.currentKind = currentKind;
                        dispatch(switchContent({ categoryIndex: 3 }));
                        // otherwise the RNModal is still on top of screen
                        Platform.OS === 'web' ? setShowDashKind(false) : null;
                      }}
                    >
                      <View style={{ paddingRight: 40 }}>
                        <View style={{ width: '100%', height: 200 }}>
                          <Text
                            style={[
                              {
                                marginRight: 10,
                                fontSize: 15,
                                marginTop: 12,
                                textAlign: 'left',
                              },
                            ]}
                          >
                            Laborwerte eintragen{'\n'}(Noch{' '}
                            {labMissingDaysLeft >= 1
                              ? labMissingDaysLeft + ' Tage Zeit'
                              : ' heute möglich'}
                            )
                          </Text>
                        </View>
                      </View>

                      <Image
                        source={require('../assets/images/add.png')}
                        style={{
                          height: 40,
                          width: 40,
                          marginRight: 0,
                          marginTop: 15,
                          paddingLeft: 15,
                          marginLeft: 'auto',
                          alignContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'row',
                        }}
                      />
                    </Pressable>
                  )}
                  {/*}*/}
                </>
              )}

              <Pressable
                style={[localStyle.dlgButton]}
                onPress={() => {
                  // otherwise the RNModal is still on top of screen
                  Platform.OS === 'web' ? setShowDashKind(false) : null;

                  navigation.navigate(Routes.OVERVIEW_SURVEY);
                }}
              >
                <View style={{ paddingRight: 40 }}>
                  <View style={{ width: '100%' }}>
                    <Text
                      style={[
                        {
                          marginRight: 10,
                          fontSize: 15,
                          marginTop: 22,
                          textAlign: 'left',
                          ...(Platform.OS === 'web' && {
                            textColor: theme.colors.primary,
                          }),
                        },
                      ]}
                    >
                      Bisherige Fragebögen
                    </Text>
                  </View>
                </View>

                <Image
                  source={require('../assets/images/ausklappen.png')}
                  style={{
                    width: 15,
                    height: 30,
                    marginRight: 0,
                    marginTop: -15,
                    paddingLeft: 15,
                    marginLeft: 'auto',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                />
              </Pressable>

              {currentKind.kind_gesund !== '1' && (
                <View>
                  <Pressable
                    style={[localStyle.dlgButton]}
                    onPress={() => {
                      Platform.OS === 'web' ? setShowDashKind(false) : null;
                      navigation.navigate(Routes.LAB_RESULTS);
                    }}
                  >
                    <View style={{ paddingRight: 40 }}>
                      <View style={{ width: '100%', height: 200 }}>
                        <Text
                          style={[
                            {
                              marginRight: 10,
                              fontSize: 15,
                              marginTop: 22,
                              textAlign: 'left',
                              ...(Platform.OS === 'web' && {
                                textColor: theme.colors.primary,
                              }),
                            },
                          ]}
                        >
                          Bisherige Laborwerte
                        </Text>
                      </View>
                    </View>

                    {/*<Image*/}
                    {/*  source={require('../assets/images/ausklappen.png')}*/}
                    {/*  style={{*/}
                    {/*    width: 15,*/}
                    {/*    height: 30,*/}
                    {/*    marginRight: 0,*/}
                    {/*    marginTop: -15,*/}
                    {/*    paddingLeft: 15,*/}
                    {/*    marginLeft: 'auto',*/}
                    {/*    alignContent: 'center',*/}
                    {/*    alignItems: 'center',*/}
                    {/*    flexDirection: 'row',*/}
                    {/*  }}*/}
                    {/*/>*/}
                  </Pressable>



                    <RNModal
                        transparent={true}
                        visible={showDialog}
                        style={{ margin: -40, padding: 40 }}
                        backdropColor={"black"}
                        backdropOpacity={0.7}
                    >
                        <View
                            style={[
                                localStyle.centeredView,
                                {
                                    backgroundColor: 'rgba(0,0,0,0.5)',
                                },
                            ]}
                        >
                            <View style={localStyle.modalView}>
                                <View style={[localStyle.titleWrapper, {position: 'relative', width: '100%'}]}>
                                    <Text
                                        style={localStyle.modalTitle}
                                        accessibilityRole={translate('accessibility').types.header}
                                    >{dialogTitle}</Text>

                                    <IconButton
                                        icon="close"
                                        iconColor={theme.colors.black}
                                        size={30}
                                        onPress={() => {
                                            setShowDialog(!showDialog);
                                        }}
                                        style={{ right: -20, top: 8, position: 'absolute'}}
                                        containerColor={theme.colors.white}
                                        mode={'contained'}
                                    />
                                </View>
                                <View style={{ marginLeft: 15, marginRight: 15}}>

                                    <Text>{dialogMessage}</Text>


                                    {/*{!!confirmButton && !!confirmButton.label &&*/}

                                    {/*    <Pressable*/}
                                    {/*        style={[localStyle.button, localStyle.buttonBordered]}*/}
                                    {/*        onPress={() => {*/}
                                    {/*            setModalVisible(!modalVisible);*/}
                                    {/*            setTimeout(() => {confirmButton?.onPress?.()}, 500)*/}
                                    {/*        }}>*/}

                                    {/*        {confirmButton?.source &&*/}
                                    {/*            <Image source={confirmButton?.source}*/}
                                    {/*                   style={{height: 40, width: 40, marginRight: 20}}/>*/}
                                    {/*        }*/}

                                    {/*        <Text style={[{*/}
                                    {/*            marginRight: 10, fontSize: 15,*/}
                                    {/*            textAlign: 'left',*/}
                                    {/*        }]}>{confirmButton?.label}</Text>*/}
                                    {/*    </Pressable>*/}

                                    {/*}*/}
                                </View>

                            </View>
                        </View>
                    </RNModal>



                </View>
              )}
            </View>
          </ScrollViewWeb>
        </RNModal>
      )}
    </View>
  );
}

SurveyScreen.propTypes = {
  navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle =
  // Platform.OS === 'native'
    // ?
    StyleSheet.create({
        dashModal: {
          // justifyContent: 'flex-end',
          marginLeft: 0,
          marginRight: 0,
          marginBottom: 0,
          borderRadius: 10,
          padding: 20,
          width: 800,
          alignSelf: 'center'
        },

        dashModalIcon: {
          marginTop: 5,
          height: 60,
          width: 60,
        },

        buttonDash: {
          marginTop: appConfig.scaleUiFkt(5),
          marginStart: appConfig.scaleUiFkt(30),
          marginEnd: appConfig.scaleUiFkt(30),
        },
        buttonSubmitDash: {
          height: appConfig.scaleUiFkt(60),
          marginBottom: appConfig.scaleUiFkt(10),
        },
        buttonSubmitTextDash: {
          fontSize: appConfig.scaleUiFkt(20),
          color: '#ffffff',
          textAlignVertical: 'center',
        },

        wrapper: {
          backgroundColor: theme.values.defaultBackgroundColor,
         },

        flexi: {
          flex: 1,
        },

        bottom: {
          alignSelf: 'flex-end',
          justifyContent: 'center',
          flex: 1 / 6,
          width: '100%',
        },

        button: {
          ...theme.classes.buttonPrimary,
          bottom: 0,
          width: '80%',
          textAlign: 'center',
        },

        buttonComplete: {
          backgroundColor:
            theme.values.defaultSendQuestionnaireButtonBackgroundColor,
        },

        buttonAlert: {
          backgroundColor: theme.colors.alert,
        },

        buttonLabel: {
          ...theme.classes.buttonLabel,
        },

        dlgButton: {
          marginTop: 20,
          marginLeft: 20,
          marginRight: 20,

          paddingLeft: 20,
          paddingRight: 20,

          paddingTop: 0,
          paddingBottom: 70,

          // flex: 1,
          // flexDirection: 'row',
          borderRadius: 5,
          borderWidth: 0,
          backgroundColor: '#EDEDED',
          height: 60
        },

        infoText: {
          textAlign: 'center',
          alignSelf: 'center',
          color: theme.colors.accent4,
          ...theme.fonts.body,
        },

        familyTitle: {
          ...theme.fonts.title2,
          marginLeft: 20,
          marginTop: 30,
          color: theme.values.defaultTitleTextColor,
        },

        content: {
          paddingHorizontal: 50,
          paddingVertical: 20,
        },

        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            alignSelf: 'center',
            height: '100%',
        },
        modalView: {
            margin: 20,
            backgroundColor: 'white',
            width: 700,
            alignSelf: 'center',
            borderRadius: 20,
            padding: 25,
            paddingTop: 0,
            alignItems: 'center',
        },
        titleWrapper: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 12,
            alignItems: 'center',
            width: I18nManager.isRTL ? '100%' : 'auto',

            paddingTop: 10,

            backgroundColor: theme.colors.white,
        },

        modalTitle: {
            ...theme.fonts.header2,
            fontSize: 20,
            paddingTop: 10,
            ...theme.fonts.header1,
            color: theme.values.defaultModalTitleColor,
        },

        headerImage: {
            width: 1000,
            height: 250,
            backgroundColor: 'white',
            alignSelf: 'center'
        },
      })
    // : StyleSheet.create({
    //     dashModal: {
    //       // justifyContent: 'flex-end',
    //       marginLeft: 0,
    //       marginRight: 0,
    //       marginBottom: 0,
    //       borderRadius: 10,
    //       padding: 20,
    //      },
    //     dashModalIcon: {
    //       marginTop: 5,
    //       height: 60,
    //       width: 60,
    //     },

    //     buttonDash: {
    //       marginTop: appConfig.scaleUiFkt(5),
    //       marginStart: appConfig.scaleUiFkt(30),
    //       marginEnd: appConfig.scaleUiFkt(30),
    //     },
    //     buttonSubmitDash: {
    //       height: appConfig.scaleUiFkt(60),
    //       marginBottom: appConfig.scaleUiFkt(10),
    //     },
    //     buttonSubmitTextDash: {
    //       fontSize: appConfig.scaleUiFkt(20),
    //       color: '#ffffff',
    //       textAlignVertical: 'center',
    //     },

    //     wrapper: {
    //       backgroundColor: theme.values.defaultBackgroundColor,
    //      },

    //     content: {
    //       paddingHorizontal: 50,
    //       paddingVertical: 20,
    //     },

    //     flexi: {
    //       flex: 1,
    //     },

    //     bottom: {
    //       alignSelf: 'flex-end',
    //       justifyContent: 'center',
    //       flex: 1 / 6,
    //       width: '100%',
    //     },

    //     button: {
    //       ...theme.classes.buttonPrimary,
    //       bottom: 0,
    //       width: '80%',
    //       textAlign: 'center',
    //     },

    //     buttonComplete: {
    //       backgroundColor:
    //         theme.values.defaultSendQuestionnaireButtonBackgroundColor,
    //     },

    //     buttonAlert: {
    //       backgroundColor: theme.colors.alert,
    //     },

    //     buttonLabel: {
    //       ...theme.classes.buttonLabel,
    //     },

    //     dlgButton: {
    //       marginTop: 20,
    //       marginLeft: 20,
    //       marginRight: 20,

    //       paddingLeft: 20,
    //       paddingRight: 20,

    //       paddingTop: 0,
    //       paddingBottom: 70,

    //       flex: 1,
    //       flexDirection: 'row',
    //       borderRadius: 5,
    //       borderWidth: 0,
    //       backgroundColor: '#EDEDED',
    //     },

    //     infoText: {
    //       textAlign: 'center',
    //       alignSelf: 'center',
    //       color: theme.colors.accent4,
    //       ...theme.fonts.body,
    //     },

    //     familyTitle: {
    //       ...theme.fonts.title2,
    //       fontSize: 40,
    //       marginLeft: 20,
    //       marginTop: 30,
    //       color: theme.values.defaultTitleTextColor,
    //     },
      // })
      ;

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default SurveyScreen;
