import React, {Component, useEffect, useState} from "react";
// TODO: check if AppState is supported for web
import {
    StyleSheet,
    View,
    Image,
    TouchableOpacity,
    Text,
    Platform,
    Dimensions,
    useWindowDimensions,
    Pressable,
    AppState
} from "react-native";
import DropDownPicker from "react-native-dropdown-picker";
import {LineChart} from "react-native-chart-kit";
import moment from "moment";
import RenderHtml from "react-native-render-html";
import {theme} from "../config";
import {useDispatch, useSelector} from "react-redux";
import AppLock from "../components/security/AppLock";
import {ScrollIndicatorWrapper} from "../components/shared";

import RNHTMLtoPDF from "../components/shared/RNHTMLtoPDF";
import FileViewer from "react-native-file-viewer";

// for web
import jsPDF from 'jspdf';

import {Button} from "react-native-paper";
import questionnaireAnalyzer from "../services/questionnaireAnalyzer";
import {parseInteger} from "jsdom/lib/jsdom/living/helpers/strings";
import {switchContent} from "../store/questionnaire.slice";
import GLOBAL from "../config/globals";
// TODO: check if this is supported for web
import { setIsBiometryAuthenticated } from '../store/globals.slice';

function LabResultsScreen({props, navigation}) {
    const dispatch = useDispatch();

    let [chartData, setChartData] = useState([]);
    let [chartLabels, setChartLabels] = useState([]);
    let [listData, setListData] = useState([]);

    const [pickerOpen, setPickerOpen] = useState(false);
    const [pickerValue, setPickerValue] = useState(null);

    useEffect(
        () => {
            navigation.removeListener('blur');
            navigation.addListener('blur', (e) => {
                setChartData([]);
                setChartLabels([]);
                setListData([]);
                setPickerOpen(false);
                setPickerValue(null);
            })
        },
        []
    );

    const {itemMap, categories} = useSelector(
        (state) => state.Questionnaire,
    );
    const [currentKind, setCurrentKind] = useState({});

    const {globalCurrentKind} = useSelector((state) => state.User);
    useEffect(() => {
        GLOBAL.currentKind = globalCurrentKind;
        setCurrentKind(globalCurrentKind);
    }, [globalCurrentKind]);

    const {
        registryDataErstmeldebogenKind,
        registryDataVerlaufsbogenKind
    } = useSelector((state) => state.RegistryData);

    let labItems = [];
    let labItemsGewicht = [];
    let labItemsGroesse = [];
    registryDataErstmeldebogenKind?.map((kind) => {
        if (kind.kind_kind_id !== currentKind.kind_kind_id) {
            return;
        }

        labItemsGewicht.push({
            date: new Date(kind.kind_gewicht_datum.substring(0, 10)),
            text: kind.kind_gewicht + " kg",
            number: parseInteger(kind.kind_gewicht)
        });
        labItemsGroesse.push({
            date: new Date(kind.kind_groesse_datum.substring(0, 10)),
            text: kind.kind_groesse + " cm",
            number: parseInteger(kind.kind_groesse)
        });
    })

    registryDataVerlaufsbogenKind?.map((verlaufsbogen, verIdx) => {
        if (verlaufsbogen.ver_kind_id !== currentKind.kind_kind_id) {
            return;
        }

        labItemsGewicht.push({
            date: new Date(verlaufsbogen.ver_gewicht_datum.substring(0, 10)),
            text: verlaufsbogen.ver_gewicht + " kg",
            number: parseInteger(verlaufsbogen.ver_gewicht)
        });
        labItemsGroesse.push({
            date: new Date(verlaufsbogen.ver_groesse_datum.substring(0, 10)),
            text: verlaufsbogen.ver_groesse + " cm",
            number: parseInteger(verlaufsbogen.ver_groesse)
        });
    });

    labItemsGewicht.sort((a, b) => a.date - b.date);
    labItemsGroesse.sort((a, b) => a.date - b.date);

    labItems.push({
        label: 'Gewicht',
        value: 'Gewicht',
        origCode: 'gewicht',
        labResults: labItemsGewicht
    });

    labItems.push({
        label: 'Größe',
        value: 'Größe',
        origCode: 'groesse',
        labResults: labItemsGroesse
    });

    let categoryIndex = 3;

    categories?.[categoryIndex]?.item.forEach((item) => {
        console.log('iterating', JSON.stringify(item));
        if (item.fieldAnnotation?.includes('[labvalue]')) {
            let text = item.text;
            if (item.fieldAnnotation.includes('[displaysummarytext|')) {
                text = item.fieldAnnotation;
                const summaryTextRegex = /\[displaysummarytext\|(.+?)\]/g;
                let matches = text.matchAll(summaryTextRegex);
                for (let captureGroups of matches) {
                    text = captureGroups[1];
                }
            }

            labItems.push({
                label: text,
                value: text,
                origCode: item.origCode
            });
        }
    });

    const {
        registryLaborwerte
    } = useSelector((state) => state.RegistryData);
    console.log('registryLaborwerte', registryLaborwerte);

    let linkIds = itemMap ? Object.keys(itemMap) : [];

    labItems.forEach((labItem) => {
        if (!!labItem.origCode) {

            categories?.[categoryIndex]?.item.forEach((item) => {
                if (item.origCode === labItem.origCode) {

                    let answerSummary = undefined;
                    let labResults = [];
                    if (item.type === 'display') {
                        answerSummary = '';
                        console.log('gettingI', item.origCode, item.fieldAnnotation)
                        if (item.fieldAnnotation.includes('[displaysummaryanswer')) {
                            answerSummary = item.fieldAnnotation;
                            console.log('gettingF', item.origCode, answerSummary)
                            const summaryTextRegex = /\[displaysummaryanswer\?(.+?)\|(.+?)\|(.+?)\]/g;
                            let matches = answerSummary.matchAll(summaryTextRegex);
                            for (let captureGroups of matches) {
                                answerSummary = captureGroups[2];
                            }
                            console.log('gettingH', item.origCode, answerSummary)
                        }
                        console.log('gettingE', item.origCode, answerSummary)

                        const fieldEmbeddingRegex = /\{(.+?)\}/g;
                        let matches = answerSummary.matchAll(fieldEmbeddingRegex);

                        registryLaborwerte.forEach((laborwert) => {
                            if (laborwert.labor_kind_id !== currentKind.kind_kind_id) {
                                return;
                            }
                            if (!laborwert[item.origCode + "_wert"]) {
                                return;
                            }

                            let unit = laborwert[item.origCode + "_einheit"];
                            if (!unit) {
                                for (let linkId of linkIds) {
                                    let origCode = itemMap[linkId]?.origCode;
                                    if (!!origCode && origCode === item.origCode + "_einheit") {
                                        unit = itemMap[linkId].text
                                    }
                                }
                            }

                            for (let linkId of linkIds) {
                                let origCode = itemMap[linkId]?.origCode;
                                if (!!origCode && origCode === item.origCode + "_wert") {
                                    let factor = 1;
                                    let textUnit = unit ?? '';
                                    if (
                                        item.origCode === 'labor_gesbilrub' ||
                                        item.origCode === 'labor_dirbilrub' ||
                                        item.origCode === 'labor_indirbilrub') {

                                        console.log('conversion1')
                                        // leading unit is mg/dl (factor 1)
                                        if (unit === 'umol_l') {
                                            console.log('conversion4')
                                            factor = 1 / 17.1;
                                            unit = 'mg_dl';
                                        }
                                        textUnit = 'mg/dl';
                                    } else if (
                                        item.origCode === 'labor_geseiweiss' ||
                                        item.origCode === 'labor_albumin') {
                                        console.log('conversion2')
                                        // leading unit is g/l (factor 1)
                                        if (unit === 'g_dl') {
                                            console.log('conversion5')
                                            factor = 10;
                                            unit = 'g_l';
                                        } else if (unit === 'mg_dl') {
                                            factor = 1 / 100;
                                            unit = 'g_l';
                                        }
                                        textUnit = 'g/l';
                                    } else if (
                                        item.origCode === 'labor_cholin') {
                                        console.log('conversion3')
                                        // leading unit is U/l (factor 1)
                                        if (unit === 'kU_l') {
                                            console.log('conversion6')
                                            factor = 1000;
                                            unit = 'U_l';
                                        }
                                        textUnit = 'U/l';
                                    }


                                    if (itemMap[linkId].type === 'integer') {
                                        // let answer = answerSummary.replace(captureGroups[0], laborwert[origCode]);
                                        // let answer = answerSummary.replace(captureGroups[0], laborwert[origCode]);
                                        let wert = parseInteger(laborwert[item.origCode + "_wert"]);
                                        if (factor < 1) {
                                            wert = (wert * factor).toFixed(2);
                                        }
                                        let laborwertText = wert + (textUnit ?? '');

                                        let labResult = {
                                            date: laborwert.labor_datum,
                                            text: laborwertText,
                                            number: parseInteger(laborwert[item.origCode + "_wert"]) * factor
                                        };
                                        labResults.push(labResult)
                                    } else if (itemMap[linkId].type === 'decimal') {
                                        // let answer = answerSummary.replace(captureGroups[0], laborwert[origCode]);
                                        let wert = parseFloat(laborwert[item.origCode + "_wert"]);
                                        if (factor < 1) {
                                            wert = (wert * factor).toFixed(2);
                                        }
                                        let laborwertText = wert + ' ' + textUnit;
                                        let labResult = {
                                            date: laborwert.labor_datum,
                                            text: laborwertText,
                                            number: parseFloat(laborwert[item.origCode + "_wert"]) * factor
                                        };
                                        labResults.push(labResult)
                                    }
                                }
                                labResults.sort((a, b) => a.date.localeCompare(b.date));
                            }

                        });


                        if (matches) {
                            console.log('gettingD', item.origCode, matches)
                            for (let captureGroups of matches) {
                                console.log("captureGroupsB ", captureGroups, answerSummary);
                                let embeddedFieldName = captureGroups[1];
                                for (let linkId of linkIds) {
                                    let origCode = itemMap[linkId]?.origCode;
                                    if (!!origCode && origCode === embeddedFieldName) {

                                        let show =
                                            questionnaireAnalyzer.checkConditionsOfSingleItem(
                                                itemMap[linkId],
                                                itemMap,
                                            )
                                        console.log('show', show)

                                        registryLaborwerte.forEach((laborwert) => {
                                            if (laborwert.labor_kind_id !== currentKind.kind_kind_id) {
                                                return;
                                            }
                                            if (laborwert[origCode] == '') {

                                            }
                                        })
                                        break;
                                    }
                                }
                            }
                        }
                    }

                    labItem['labResults'] = labResults;
                }
            });
        }
    })


    const {width} = useWindowDimensions();

    const {isBiometryAuthenticated} = useSelector((state) => state.Globals);
    const {useAppLock} = useSelector((state) => state.User);
    useEffect(() => {
        setAppLock(useAppLock);
    }, [useAppLock]);
    useEffect(() => {
        setIsAuthenticated(isBiometryAuthenticated);
    }, [isBiometryAuthenticated]);
    const [appLock, setAppLock] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [appState, setAppState] = useState('unknown');
    const handleAppStateChange = (newAppState) => {
        console.log('newAppState', newAppState);
        setAppState(newAppState);
        if (newAppState === 'background' || newAppState === 'unknown') {
            dispatch(setIsBiometryAuthenticated(false));
        }
    };

    useEffect(() => {
        AppState.addEventListener('change', handleAppStateChange);
        return () => {
            AppState.removeEventListener('change', handleAppStateChange);
        };
    }, []);

    // List all available Lab Results
    // Select Lab results to display


    let createPDF = async () => {
        let htmlSource = '<div style="width:1350px">';
        htmlSource += '<h1>Alpha-1-KIDS Laborwerte</h1>';

        let datesGroesseGewicht = [];
        let datesLab = [];

        labItems.forEach((labItem) => {
            labItem.labResults?.forEach((labResult) => {
                if (!!labResult.number) {
                    let found = false;
                    if (labItem.origCode === 'groesse' || labItem.origCode === 'gewicht') {
                        datesGroesseGewicht.forEach((d) => {
                            if (moment(d).isSame(labResult.date)) {
                                found = true;
                            }
                        })
                        if (!found) {
                            datesGroesseGewicht.push(labResult.date)
                        }
                    } else {
                        datesLab.forEach((d) => {
                            if (moment(d).isSame(labResult.date)) {
                                found = true;
                            }
                        })
                        if (!found) {
                            datesLab.push(labResult.date)
                        }
                    }

                }
            });
        });
        htmlSource += '<table border="1" cellpadding="3" cellspacing="0" style="border: 2px solid #999999;">';
        htmlSource += '<tr><td></td>';

        datesGroesseGewicht.forEach((d) => {
            htmlSource += '<td style="text-align: right;">' + moment(d, 'YYYY-MM-DD').locale('de').format('DoMM.[<br />]YYYY') + '</td>';
        })
        labItems.forEach((labItem) => {
            if (!(labItem.origCode === 'gewicht' || labItem.origCode === 'groesse')) {
                return;
            }

            htmlSource += '<tr><td style="font-weight: bold; text-align: right;">' + labItem.label + '</td>';

            for (let i = 0; i < datesGroesseGewicht.length; i++) {
                htmlSource += '<td style="text-align: right;">';
                labItem.labResults?.forEach((labResult) => {
                    if (!!labResult.number) {
                        if (moment(datesGroesseGewicht[i]).isSame(labResult.date)) {
                            htmlSource += labResult.text;
                        }
                    }
                })
                htmlSource += '</td>';
            }
            htmlSource += '</tr>';

        })
        htmlSource += '</tr>';
        htmlSource += '</table>';

        htmlSource += '<table border="1" cellpadding="3" cellspacing="0" style="margin-top: 5px; border: 2px solid #999999;">';
        htmlSource += '<tr><td></td>';

        datesLab.sort((a, b) => moment(a).diff(moment(b)));
        datesLab.forEach((d) => {
            htmlSource += '<td style="text-align: right;">' + moment(d, 'YYYY-MM-DD').locale('de').format('DoMM.[<br />]YYYY') + '</td>';
        })
        labItems.forEach((labItem) => {
            if ((labItem.origCode === 'gewicht' || labItem.origCode === 'groesse')) {
                return;
            }

            htmlSource += '<tr><td style="font-weight: bold; text-align: right;">' + labItem.label + '</td>';

            for (let i = 0; i < datesLab.length; i++) {
                htmlSource += '<td style="text-align: right;">';
                labItem.labResults?.forEach((labResult) => {
                    if (!!labResult.number) {
                        if (moment(datesLab[i]).isSame(labResult.date)) {
                            htmlSource += labResult.text;
                        }
                    }
                })
                htmlSource += '</td>';
            }
            htmlSource += '</tr>';
        })
        htmlSource += '</tr>';
        htmlSource += '</table>';
        htmlSource += '<p>Diese Übersicht wurde mit Hilfe der Alpha-1-KIDS-App erstellt (www.alpha1kids.de).<br \>Für die Richtigkeit der Werte wird keine Verantwortung übernommen.</p>';
        htmlSource += '</div>';

        let options = {
            html: htmlSource,
            fileName: 'Laborwerte-alpha-1-kids.pdf',
            directory: 'Documents'
        };

        if (Platform.OS === 'native') {
            let file = await RNHTMLtoPDF.convert(options);
            FileViewer.open(file.filePath) // absolute-path-to-my-local-file.
                .then(() => {

                })
                .catch((error) => {

                });

             if (!isAuthenticated && appLock && appState === 'background') {
               return <></>;
             } else if (
               !isAuthenticated &&
               appLock &&
               (appState === 'active' || appState === 'inactive')
             ) {
               return <AppLock />;
             }

        } else {
            // if on web, download the file using jsPDF
            // TODO: jsPDF doesn't support adding margins to the page, try adding padding to the html
            const doc = new jsPDF({
                orientation: 'l',
            });
            doc.html(options.html, {
                html2canvas: {
                    // insert html2canvas options here, e.g.
                    scale: 0.25,
                    width: 800
                },
                margin: [10,10,10,10],
                callback: async function (doc) {
                    await doc.save(options.fileName);
                },
		});

        }
    }


    return (
        <View style={styles.container}>
            <Text

                style={[styles.familyTitle, {
                    marginLeft: 0,
                    marginTop: 0,
                    fontSize: 24,
                    marginBottom: 8
                }]}>Laborwerte für {currentKind.kind_vorname + ' ' + currentKind.kind_nachname}</Text>

            <Text

                style={[styles.familyTitle, {
                    marginLeft: 0,
                    marginTop: 0,
                    fontSize: 18,
                    marginBottom: 4
                }]}>Auswahl Messwert:</Text>

            <DropDownPicker
                containerStyle={{marginTop: 20, marginBottom: 20}}
                open={pickerOpen}
                setOpen={setPickerOpen}
                value={pickerValue}
                setValue={setPickerValue}
                items={labItems}
                onSelectItem={(item) => {
                    console.log('selectedItem', item);


                    let l = [];
                    let data = [];

                    item.labResults?.forEach((labResult) => {
                        if (!!labResult.number) {
                            l.push(moment(labResult.date, 'YYYY-MM-DD').locale('de').format('Do MMM YYYY'));
                            data.push(labResult.number);
                        }

                    })
                    setChartLabels(l)
                    setChartData(data);
                    setListData(item.labResults);
                }}
                listMode={"FLATLIST"}

                textStyle={{
                    fontSize: 18
                }}
                placeholder={"Wählen Sie aus"}
            />

            <ScrollIndicatorWrapper>
                <View>
                    {chartData.length == 0 && !!pickerValue &&
                        <Text style={{textAlign: 'center'}}>(Keine Werte für {pickerValue} vorhanden)</Text>}

                    {chartData.length == 0 && !pickerValue &&
                        <Text style={{textAlign: 'center'}}>Wählen Sie bitte einen Messwert zur Anzeige{'\n'} aus dem
                            Drop-Down Menü aus</Text>}

                    {chartData.length > 0 &&
                    <LineChart
                        data={{
                            labels: chartLabels,
                            datasets: [
                                {
                                    data: chartData
                                }
                            ]
                        }}
                        fromZero={true}
                        width={Dimensions.get("window").width - 40} // from react-native
                        height={330}
                        withShadow={false}
                        verticalLabelRotation={-70}
                        xLabelsOffset={30}
                        chartConfig={{
                            backgroundColor: "#A50000",
                            backgroundGradientFrom: "#4CAAED",
                            backgroundGradientTo: "#71618A",
                            propsForLabels: {
                                fontWeight: 'bold'
                            },
                            decimalPlaces: 0, // optional, defaults to 2dp
                            color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                            labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                            style: {
                                borderRadius: 13,
                            },
                            propsForDots: {
                                r: 5,
                                strokeWidth: 2,
                                stroke: "#4D658D"
                            }
                        }}
                        style={{
                            marginBottom: 20,
                            borderRadius: 10
                        }}
                    />}


                    <View style={{
                        borderBottomColor: 'black',
                        borderBottomWidth: 1,
                        paddingTop: 10,
                        paddingBottom: 10,
                        marginBottom: 10,
                        marginLeft: 40,
                        marginRight: 40,
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                        <Text style={{fontWeight: 'bold'}}>
                            Datum
                        </Text>
                        <Text style={{fontWeight: 'bold'}}>
                            Wert
                        </Text>
                    </View>
                    {listData.map((d, i) => {
                        return <>
                            <View style={{
                                borderBottomColor: 'black',
                                borderBottomWidth: 1,
                                paddingTop: 5,
                                paddingBottom: 10,
                                marginBottom: 10,
                                marginLeft: 40,
                                marginRight: 40,
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}>
                                <Text>
                                    {moment(d.date, 'YYYY-MM-DD').locale('de').format('Do MMMM YYYY')}
                                </Text>
                                <Text style={{color: theme.colors.primary, fontWeight: 'bold'}}>
                                    {d.text}
                                </Text>
                            </View>
                        </>
                    })


                    }</View>
            </ScrollIndicatorWrapper>

            <Pressable
                style={[styles.dlgButton, styles.dlgButtonBordered]}
                onPress={createPDF}>
                <Image source={require('../assets/images/pdf.png')}
                       style={{height: 40, width: 40, marginRight: 20}}/>

                <Text style={[{
                    marginRight: 10, fontSize: 15,
                    textAlign: 'left',
                }]}>Alle Laborwerte als{'\n'}PDF-Datei exportieren</Text>
            </Pressable>

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        padding: 20,
        backgroundColor: theme.values.defaultBackgroundColor,
    },
    logoStack: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center"
    },
    logo: {
        top: 0,
        height: 100,
        width: 200
    },
    buttonRow: {
        flex: 1,
        flexDirection: "row",
        backgroundColor: "aliceblue",
        justifyContent: "space-evenly",
        paddingEnd: 15,
        paddingStart: 15
    },

    mainButton: {
        width: 160,
        height: 160,
        backgroundColor: "rgba(255,255,255,1)",
        borderRadius: 34,
        elevation: 10,
        shadowColor: '#52006A',
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: 10
    },
    icon: {
        marginTop: 5,
        height: 70,
        width: 70,
        marginBottom: 8
    },
    buttonText: {
        fontFamily: "roboto-500",
        color: "#121212",
        fontSize: 25,
        marginTop: 5
    },


    dlgButton: {
        flexDirection: "row",
        alignContent: "center",
        alignSelf: "center",
        alignItems: "center",
        borderRadius: 10,
        padding: 5,
        marginBottom: 20
    },

    dlgButtonBordered: {
        borderWidth: 3,
        padding: 7,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 10,
        borderColor: theme.colors.primary
    },
});

export default LabResultsScreen;
